import { ReactElement } from 'react';
import {
    Box, SimpleGrid, Icon, Text, Stack, Flex, useColorModeValue, Image,
    Center,
} from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit } from 'react-icons/fc';


import chinatechnews from './assets/logos-accolades/chinatechnews.svg'
import fintechinnovation from './assets/logos-accolades/fintechinnovation.svg'
import hkcd from './assets/logos-accolades/hkcd.svg'
// import ict2015 from './assets/logos-accolades/ict2015.svg'
import ict2016 from './assets/logos-accolades/ict2016.svg'
import internetfinance from './assets/logos-accolades/internetfinance.svg'
import nexchangenow from './assets/logos-accolades/nexchangenow.svg'
import oncc from './assets/logos-accolades/oncc.svg'
import orangenews from './assets/logos-accolades/orangenews.svg'
import scmp from './assets/logos-accolades/scmp.svg'
import singtao from './assets/logos-accolades/singtao.svg'
import startupbeat from './assets/logos-accolades/startupbeat.svg'

interface FeatureProps {
    title: string;
    text: string;
    icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
    return (
        <Center>
            <Flex
                // w={16}
                // h={16}
                // align={'center'}
                // justify={'center'}
                // color={'white'}
                // rounded={'full'}
                // bg={useColorModeValue('white', 'gray.800')}
                mb={1}
                >
                {icon}
            </Flex>
        </Center>
    );
};

export default function SimpleFourColumns() {
    return (
        <Box
        bg={'#fff'}
        px={{base: 4, md:'none'}}
        py={4}
        >
            <Text textAlign={['left', 'center']} 
            fontSize={{base:'3xl',md:'5xl'} }
            pb={8}
            px={{ base: 4}}
            color='black'
            >As seen on</Text>
            <Box
                // bg={useColorModeValue('white', 'white')}
            >

                <Center>
                    <SimpleGrid columns={{ base: 3, md: 4 }} spacing={10}
                        alignContent='center'
                        px={{ base: 4, md: 0 }}
                        w={'full'}
                        maxW={'6xl'}
                    >
                        <Feature icon={<Image src={chinatechnews} w={40} h={10} />} />
                        <Feature icon={<Image src={fintechinnovation} w={40} h={10} />} />
                        <Feature icon={<Image src={hkcd} w={40} h={10} />} />
                        <Feature icon={<Image src={startupbeat} w={40} h={10} />} />

                        <Feature icon={<Image src={singtao} w={40} h={10} />} />
                        {/* <Feature icon={<Image src={ict2015} w={40} h={20} />} /> */}
                        <Feature icon={<Image src={ict2016} w={40} h={20} />} />
                        <Feature icon={<Image src={nexchangenow} w={40} h={10} />} />

                        <Feature icon={<Image src={internetfinance} w={40} h={20} />} />
                        <Feature icon={<Image src={scmp} w={40} h={20} />} />
                        <Feature icon={<Image src={oncc} w={40} h={20} />} />
                        <Feature icon={<Image src={orangenews} w={40} h={20} />} />
                        

                    </SimpleGrid>
                </Center>
            </Box>
        </Box>
    );
}