import {
    
    Link,
    Avatar,
    ChakraProvider,
    Box,
    Text,
    VStack,
    theme,
    Flex,
    useBreakpointValue,
    Stack,
    Button,
    Container,
    Heading,
    Image,
    Icon,
    List,
    ListItem,
    ListIcon,
    useColorModeValue,
    Center,
    Spacer,
    extendTheme,
    mode,
} from '@chakra-ui/react';

import Navbar from '../navbar';
import Footer from '../components/footer';

import Schools from '../components/schools';
import AppStoreGroup from '../components/AppStoreGroup';
import MobileBottomAppStoreGroup from '../components/MobileBottomAppStoreGroup';

import logo from '../assets/logotest.png';

export default function about() {
    return (
        <Box>
            <Navbar />
            <AboutHero />
            {/* <AboutIntro /> */}
            {/* <AboutFeatures /> */}
            <Box h={200} />
            <Footer />
        </Box>
    )
}

function AboutHero() {
    return (
        <Container maxW={'6xl'}
            w={'full'}
        // h={'100vh'}
        >
            <Center pt={{ base: 20, md: 40 }}
                pb={20}
            >
                <VStack >
                <Image src={logo}
                    w={{ base: 'auto', md: 'xl' }}
                />



          <Flex display={{ base: 'none', md: 'flex' }}
          pt={40}
          >
            <div id='getstarted'>
            <AppStoreGroup />
            </div>
          </Flex>
        </VStack>

        <MobileBottomAppStoreGroup />
            </Center>
        </Container>
    );
}

function AboutIntro() {
    return (
        <Stack
            // bg={useColorModeValue('gray.50', 'gray.800')}
            bg={'white'}
            color={'gray.800'}
            py={16}
            px={8}
            spacing={{ base: 8, md: 10 }}
            align={'center'}
            direction={'column'}>
            <Text
                fontSize={{ base: 'xl', md: '2xl' }}
                textAlign={'center'}
                maxW={'4xl'}>
                {/* As one of the earliest payment solution pioneers, Yintran Holding Company Limited, founded in 2015 by ex-bankers, Silicon Valley engineers, fund managers and lawyers who knew the pain of writing checks, carrying cash, managing expenses, and paying high cost for cross border payment, vowed to find an easy and convenient solution for the community. */}
                We want to help build a more convenient payment experience for you, whether it's by reducing the clutter of cheques, helping you lighten the weight of loose change from your wallet, pay just about anyone at the tip of your fingers - and then some.

            </Text>
            <Text fontSize={{ base: "16px", md: "xl" }} fontWeight="normal" maxW={'4xl'}
                color='gray.600' lineHeight={2}>
                5D Pay is an eWallet powered by Yintran, a fintech based in Hong Kong.
                Our backgrounds from Hong Kong and places all over the world set the tone for
                the standard by which we benchmark our work and stress
                the importance of planting the roots of what we do in local practices.
                This means that we care both about pushing the envelope
                and about ensuring that what we build is adoptable and truly of use to
                those we serve.

                We’re the kind of people who, when we see a problem, we want to solve it;
                we observe how something has always been done, and we question if it could
                be done better. Sound like home?
                <Link href='/careers' fontWeight={500} color='blue.400'> Grow with us</Link>.
            </Text>


        </Stack>
    );
}

function AboutFeatures(props: DescriptionItem) {
    return (
        <Container maxW={'6xl'}>
            {/* who we are*/}
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                pt={{ base: 20, md: 28 }}
                direction={{ base: 'column', md: 'row' }}>
                <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                    <Heading
                        lineHeight={1}
                        fontWeight={400}
                        fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
                        <Text
                            as={'span'}
                            position={'relative'}
                        >
                            Who we are
                        </Text>
                    </Heading>
                    <Text
                        color={useColorModeValue('gray.500', 'gray.200')}>
                        <List fontSize='lg' pt={1} pb={12}
                            lineHeight={2}>
                            <ListItem pb={2}>
                                {/* <ListIcon color={'pink.300'} /> */}
                                5D Pay is an eWallet powered by Yintran, a fintech company based in Hong Kong. We have roots from Hong Kong and from all over the world.
                            </ListItem>
                        </List>
                    </Text>
                    <Stack
                        spacing={{ base: 4, sm: 6 }}
                        direction={{ base: 'column-reverse', sm: 'row' }}>
                        <Schools />
                    </Stack>
                </Stack>
                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                    position={'relative'}
                    w={'full'}>
                    <Box
                        position={'relative'}
                        height={{ base: '364px', md: '664px' }}
                        width={'500px'}
                        overflow={'hidden'}>
                        <Image
                            alt={'Transfer'}
                            fit={'cover'}
                            align={'center'}
                            w={{ base: 'auto', md: '100%' }}
                            h={{ base: 'auto', md: '100%' }}
                            src={'https://images.unsplash.com/photo-1590233033212-95081b4e52fb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDR8fHdvcmxkfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60'}
                        />
                    </Box>
                </Flex>
            </Stack>
      </Container>
    );
  }
  
