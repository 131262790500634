import {
    Box,
    Flex,
    Link,
    Image
} from '@chakra-ui/react';


export default function AppStoreGroup() {
    return (
      <Flex
        spacing={1}
        direction="row" h="85px"
        justify={{ base: "center", md: 'left' }}
        align='center'>
        <Link href='https://apps.apple.com/hk/app/5d-pay/id1503312483'>
          <Image src="appstore.svg"
            h={{ base: "48px", md: "48px" }}
            my={{ base: "4px", md: "8px" }}
          />
        </Link>
        <Box w={2} />
        <Link href='https://play.google.com/store/apps/details?id=com.yintran.pay'>
          <Image src="googleplay.svg"
            h={{ base: "48px", md: "48px" }}
            my={{ base: "4px", md: "8px" }}
          />
        </Link>
      </Flex>
    )
  }
  