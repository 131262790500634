import { ReactElement } from 'react';
import { Box, SimpleGrid, Icon, Text, Stack, Flex,useColorModeValue, Image} from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit } from 'react-icons/fc';

import topup from './assets/ic-summary/1_topup.svg'
import transfer from './assets/ic-summary/2_transfer.svg'
import zerohiddenfees from './assets/ic-summary/3_zerohiddenfees.svg'
import security from './assets/ic-summary/4_security.svg'

interface FeatureProps {
    title: string;
    text: string;
    icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
    return (
        <Stack>
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                // color={'white'}
                rounded={'full'}
                bg={useColorModeValue('white', 'gray.800')}
                mb={1}>
                {icon}
            </Flex>
            <Text fontWeight={600} fontSize={'lg'}>{title}</Text>
            <Text color={useColorModeValue('gray.500', 'gray.300')}>{text}</Text>
        </Stack>
    );
};

export default function Summary() {
    return (
        <Box p={4}>
            <Text textAlign={[ 'left', 'center' ]} fontSize={'3xl'} pb={4}>5D Pay is an eWallet and payment solution provider</Text>
            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
                <Feature
                    icon={<Image src={topup} w={10} h={10} />}
                    title={'Hassle-free topups'}
                    text={
                        'Add money to your 5D Pay balance without the headache and no minimum balance.'
                    }
                />
                <Feature
                    icon={<Image src={transfer} w={10} h={10} />}
                    title={'Instant money transfers'}
                    text={
                        'Transferring money is free for individual users.'
                    }
                />
                <Feature
                    icon={<Image src={zerohiddenfees} w={10} h={10} />}
                    title={'Zero hidden fees'}
                    text={
                        'It’s completely free to set up an account.'
                    }
                />
                <Feature
                    icon={<Image src={security} w={10} h={10} />}
                    title={'Security you can count on'}
                    text={
                        'Bank grade security against unauthorised payment transactions.'
                    }
                />
            </SimpleGrid>
        </Box>
    );
}