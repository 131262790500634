import React, {useEffect,useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';

import {

    Link,
    Avatar,
    ChakraProvider,
    Box,
    Text,
    VStack,
    theme,
    Flex,
    useBreakpointValue,
    Stack,
    Button,
    Container,
    Heading,
    Image,
    Icon,
    List,
    ListItem,
    ListIcon,
    useColorModeValue,
    Center,
    Spacer,
    extendTheme,
    mode,
    HStack,
} from '@chakra-ui/react';
import QRCode from 'qrcode.react';
import {
    useLocation
  } from "react-router-dom";
import Navbar from '../navbar';
// import Navbar-prof from '../navbar'; WORK ON THIS
import Footer from '../components/footer';

import Schools from '../components/schools';
import AppStoreGroup from '../components/AppStoreGroup';
import MobileBottomAppStoreGroup from '../components/MobileBottomAppStoreGroup';

import logo from '../assets/logotest.png';
import fdgradient from '../assets/fdgradient.png';
import sampleqr from '../assets/sampleqr.jpg';
import { fetchBooking,selectBooking} from '../integration/reducer/hotelReducer';


const IMAGE =
    'https://images.unsplash.com/photo-1518051870910-a46e30d9db16?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80';

// import GRADIENT from
//     '../assets/logo192.png';

    
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

export default function SendMeA() {

    const dispatch = useDispatch();
    const qrData=useSelector(selectBooking);
    let query = useQuery();

    
        
    let content={
        "namespace":query.get("u"),
        "paramEncoded": query.get("c")
        }
    
    useEffect(() => {
        if(query.get("u")!=null){
            dispatch(fetchBooking(content))
        }
    }, [])
    console.log("check output QRDetail:"+JSON.stringify(qrData));
    

    return (
        <Box>
            <Navbar />
            <VStack minH={{base:'60vh',sm:'80vh'}}
            pt={{base:'none',sm:'12'}}
            >
            
            <QR qrData={qrData}/>

            <Box p={4} display={{base:'none',sm:'flex'}}>
            <AppStoreGroup/>
            </Box>
            </VStack>
            <Box h={200} />
            
            <MobileBottomAppStoreGroup/>

            <Footer />
        </Box>
    )
}

function QR(props) {
    return (
        <Center py={4}>
            <Box
                role={'group'}
                px={{base:'6',md:'12'}}
                py={{base:'4',md:'6'}}
                maxW={'330px'}
                w={'full'}
                bg={'white'}
                boxShadow={'xl'}
                rounded={'lg'}
                pos={'relative'}
                zIndex={1}>
                <Box align={'center'}
                pb={2}
                >
                    <Image
                        rounded={'md'}
                        // h={230}
                        w={150}
                        objectFit={'cover'}
                        src={logo}
                    />
                </Box>
                <Box
                    rounded={'lg'}
                    // mt={-12}
                    pos={'relative'}
                    height={'220px'}
                    _after={{
                        transition: 'all .3s ease',
                        content: '""',
                        w: 'full',
                        h: 'full',
                        pos: 'absolute',
                        top: 3,
                        left: 0,
                        backgroundImage: `url(${fdgradient})`,
                        filter: 'blur(10px)',
                        zIndex: -1,
                    }}
                    _groupHover={{
                        _after: {
                            filter: 'blur(20px)',
                        },
                    }}>
                   
                   <QRCode value={props.qrData.data?props.qrData.data.qrCode:''} 
                    size={230}
		            level ='H'
                    //imageSettings={{
                      //  src: props.qrData.data?props.qrData.data.avatar:'',
                     //   x: 90,
                     //   y: 90,
                     //   height: 60,
                     //   width: 60,
                     //   excavate: true,
                     // }}
                    />
                   {/*} <Image
                    
                        rounded={'md'}
                        h={230}
                        w={230}
                        objectFit={'cover'}
                        src={sampleqr}
                />*/}
                </Box>
                <Stack pt={4} align={'center'}>
                    <HStack>
                    <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500} color={'#1e1e1e'} align={'center'}>
                    Send 
                        {/* ${object} ${nickName}*/}
                    </Heading>
                    <Image h='30px' w='30px' src={props.qrData.data?props.qrData.data.giftTypeIcon:''}/>  
                    <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500} color={'#1e1e1e'} align={'center'}>
                    to {props.qrData.data?props.qrData.data.nickName:''} {"\n"} 
                    </Heading>
                        
                    </HStack>
                    

                    <Stack px='4' py='2'
                        bg={'#fafafa'}
                        // bg={useColorModeValue('#fafafa', 'gray.600')}
                        rounded={'lg'}
                        direction={'row'} align={'left'}
                        minW={'230px'}
                        color={'#1e1e1e'}
                    // color={useColorModeValue('#1e1e1e', 'gray.100')}
                    >
                        <Text fontWeight={600} fontSize={'xl'} >
                        {props.qrData.data?props.qrData.data.currency:''}
                        </Text>
                        <Text fontWeight={600} fontSize={'xl'}>
                        {props.qrData.data?props.qrData.data.currencyFlag:''}  {props.qrData.data?props.qrData.data.amountDesc:''} 
                        </Text>
                        <Spacer />
                    </Stack>
                </Stack>
            </Box>
        </Center>
    );
}





