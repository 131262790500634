import {
  Flex,
  Center, 
  useColorModeValue} from '@chakra-ui/react';

  import AppStoreGroup from './AppStoreGroup';
  
  export default function MobileBottomAppStoreGroup() {
    return (
      <Center
        bg={useColorModeValue('white', 'gray.700')}
        color={useColorModeValue('gray.600', 'white')}
        zIndex={'9999'}
        w={'full'}
        position={{ base: 'fixed', md: 'auto' }}
        bottom={{ base: '0', md: '1' }}
        align='center'
      >
        <Flex display={{ base: '1', md: 'none' }}>
          <AppStoreGroup />
        </Flex>
      </Center>
      
    )
  }
  