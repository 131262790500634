import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { client } from '../api/client';




export const fetchBooking= createAsyncThunk('book', async (content) => {
  const  response = await client.get(content.namespace+'/'+content.paramEncoded,{});
  return response;
})

/*
export const submitBooking = createAsyncThunk(
  'reservation',
  async (booking) => {
    const response = await client.post('reservation', booking)
    return response;
  }
)
*/

const initialState = {
  booking:{},
  
}


const hotelReducer = createSlice({
  name: 'hotel',
  initialState,
  
  reducers: {

  },
  extraReducers: {
   /* 
    [submitBooking.fulfilled]: (state, action) => {
      state.booking =action.payload;
    },
    */
    [fetchBooking.fulfilled]: (state, action) => {
      state.booking =action.payload;
      //console.log("Check reducer json:"+JSON.stringify(state.booking));
    },
  },
})
export default hotelReducer.reducer;


export const selectBooking = (state) => state.hotelDetail.booking;

