import React from 'react';
import {
  ChakraProvider,

  DarkMode,
  Box,
  Text,
  VStack,
  theme,
  Flex,
  useBreakpointValue,
  Stack,
  Button,
  Container,
  Heading,
  Image,
  // Link,
  Icon,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  Center,
  Spacer,
  extendTheme,
  mode,
} from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Provider as StoreProvider } from 'react-redux';
import store from './integration/store/store.js';
import { render } from "react-dom";
// import theme from './theme';
import Home from './Home';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import Data from './pages/data';
import About from './pages/about';
import Careers from './pages/careers';
import Security from './pages/security';
import Getstarted from './pages/getstarted';

import SendMeA from './pages/SendMeA';

import Ewallet from './pages/ewallet';
import Transfers from './pages/transfers';
import Remittance from './pages/remittance';
import Cards from './pages/cards';

// import ReactDOM from 'react-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const themee = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: "dark"
  }
});


function App() {
  return (
    // <ChakraProvider theme={theme}>
<StoreProvider store={store}>
    <ChakraProvider theme={themee}>
      <Router>
        <div>
          <nav>
            <ul>
              <Link to="/"></Link>

              <Link to="/about"></Link>
              <Link to="/careers"></Link>
              <Link to="/security"></Link>

              <Link to="/getstarted"></Link>

              <Link to="/ewallet"></Link>
              <Link to="/transfers"></Link>
              <Link to="/remittance"></Link>
              <Link to="/cards"></Link>

              <Link to="/terms"></Link>
              <Link to="/privacy"></Link>
              <Link to="/data"></Link>
            </ul>
          </nav>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>

          <Route exact path="/sendmea">
              <SendMeA />
            </Route>

            <Route exact path="/">
              <Home />
            </Route>

            <Route exact path="/about">
              <About />
            </Route>

            <Route exact path="/getstarted">
              <Getstarted />
            </Route>

            <Route exact path="/careers">
              <Careers />
            </Route>

            <Route exact path="/security">
              <Security />
            </Route>


            <Route exact path="/ewallet">
              <Ewallet />
            </Route>
            <Route exact path="/transfers">
              <Transfers />
            </Route>
            <Route exact path="/remittance">
              <Remittance />
            </Route>
            <Route exact path="/cards">
              <Cards />
            </Route>




            <Route exact path="/terms">
              <Terms />
            </Route>

            <Route exact path="/privacy">
              <Privacy />
            </Route>

            <Route exact path="/data">
              <Data />
            </Route>


          </Switch>
        </div>
      </Router>

    </ChakraProvider>
    </StoreProvider>
  );
}

export default App;