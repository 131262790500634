import {
    Box,
    Text,
    VStack,
    Link,
    Center,
    useColorModeValue,
} from '@chakra-ui/react';
  
  import Navbar from '../navbar';
  import Footer from '../components/footer';
  
  export default function data(){
      return (
      <Box>
          <Navbar />
          <DataHeader />
          <Data />
          <Footer />
      </Box>
      )
  }
  
  const DataHeader = () => {
    return (
        /* Terms and Conditions - last updated... */
        <VStack
            bg={useColorModeValue('white', 'gray.800')}
        >
            <Text
                pt={{ base: 40, md: 60 }}
                pb="80px" fontSize="36px"
                align="center">
                Data Policy
            </Text>
            <Text paddingTop="98px" paddingBottom="48px" fontStyle="italic">
                Last updated on April 3, 2021
            </Text>
        </VStack>
    );
}


  const Data = () =>{
      return (
        <Center paddingBottom="280px" bg='white' color={'gray.900'} pt={4}>
  
              <Box maxW="4xl" px={{base:8, md:16}} pb={{base:20, md:40}}>

                  <Box>
                      {/* title */}
                      <Box paddingBottom="16px"> 
                          <Text color="gray" fontSize="16px">
                          Yintran Group Holdings Limited (the “Company”) is committed to protecting users’ privacy under the Hong Kong Personal Data (Privacy) Ordinance (“PDPO”) and the relevant codes of practice, guidelines and best practices issued by the Office of the Privacy Commissioner for Personal Data (“PCPD”) from time to time. This privacy policy (“Policy”) stipulates   the Company’s privacy practices for personal information the Company collects from users when users  use or access to the Company’s products, services, features, or contents through  website, applications, or services (the “Services”). The Company may, in our sole discretion, amend this Policy from time to time by posting a revised version at the Company’s website. If the change is material, the Company will separately notify you by email to your registered email address.  All changes made will not deviate from the minimum requirement set forth in the PDPO, and shall become effective in 48 hours after they are posted at the Company’s website.
                          </Text>
                      </Box>
                              
                      {/* SUBTITLE #1 */}
                      <Box paddingTop="32px" paddingBottom="16px">
                          <Text fontWeight="medium" fontSize="16px">
                              Information collection
                              </Text>
                      </Box>
  
                      {/* paragraph section */}
                      <Box color="gray" fontSize="16px">
                          <Text paddingBottom="24px" >
                          The Company will collect and process the following personal and official information, such as device UDID/Serial number, HKID or passport name and number, issue locations, mobile phone number, date of birth, nationality, source of income, source of wealth, residential address, business registered address, contacts list/address book, calendar/reminder, stored photos/videos/recordings, SMS/MMS/email message, browser history, app names/account names, user microphone/camera, biometrics, require/allow user login, invitation code (if any), other information, etc. from users that is necessary for processing user’s payment transfer or facilitating the robustness of payment transfer via the Company’s payment transfer platform (“Platform”) when users use or access the Services, or open an e-Wallet account at the Platform.  The company reserves the right to remove the content of the digital media that you upload to the Platform without reason and without any notification.  The Company may collect information that the user provides to the Company when he/she/it interacts with the Company’s customer support team or other team members via phone, email, online form, or other corresponding methods.
                              </Text>
  
                          <Text paddingBottom="24px" >
                          The Company may also collect the user’s personal information stored with third parties which is disclosed to the Company by the user, such as the user’s social media account information and social media contacts. By linking the user’s account in the third party service providers with the Platform or using third party sign-in services to access to the Platform, the user authorizes the Company to have access to the information that the user stores in the third party service providers, and the user agrees that the Company may collect and use, within the parameter of the PDPO, such information pursuant to this Policy.
                              </Text>
  
                          <Text paddingBottom="24px" >
                          The Company is free to display a personal testimonial information or endorsement that the user voluntarily provides to the Company with the user’s name on the Company’s website or other social media shall the Company obtain the user’s consent. The user may request to update or delete such testimonial information or endorsement by emailing the request to the Company.
              The Company may also collect the web browser related information from the user’s device when the user use the Services, including but not limited to the IP address used by the user’s device, the type of device the user uses, the device identifier, the device’s operating system version, the type of browser the user uses, the user’s location microphone calendars contacts, and the URL that the user came from and the next URL that the user visits. If the user has   turned on collection of location data in the Company’s mobile application, the user may choose to turn it off in the Settings on Android and on iOS.
                              </Text>
  
                          <Text paddingBottom="24px" >
                          When the user visits the Company’s website or uses the Company’s mobile application, the Company will collect the IP address in the user’s device and the standard web log information, such as the user’s browser type and the pages you accessed in the Company’s website. We also may collect certain geo-location information from the user. If the user does not agree with   the Company’s collection of the aforementioned information, the user shall discontinue the Company’s Services.
                              </Text>
                      </Box>
  
                      {/* SUBTITLE #2 */}
                      <Box paddingTop="32px" paddingBottom="16px">
                          <Text fontWeight="medium" fontSize="16px">
                              Sharing collected information
                              </Text>
                      </Box>
  
                      {/* paragraph section */}
                      <Box color="gray" fontSize="16px">
                          <Text paddingBottom="24px" >
                          The Company may use the collected information to seek the approval from the user’s bank for linking the user’s bank account with the user’s e-Wallet account, and subsequently verify the validity of the user’s bank account with the provided information such as the Proxy ID which includes mobile number, email address, or a FPS ID, etc. When the user makes payment transfer by using the Services, the Company will provide the user’s name and photo uploaded by the user to the user who receives the payment. In the event that the payment transfer fails, the Company may provide the Sender with details of the related payment.
                              </Text>
  
                          <Text paddingBottom="24px" >
                          The Company may further share the collected information with the Company’s partners and affiliates, Hong Kong Monetary Authority licensed banks, and related parties which assist in maintaining and improving the Services, including fraud prevention organization identity verification organization, analytics and marketing services entities, and financial institutions and other partners that are involved in  the payment process.
                              </Text>
  
                          <Text paddingBottom="24px" >
                          The Company may also confirm with a vendor or a third party, based upon the user’s request, the user’s e-Wallet account status with the Company.
              Upon the request of a government or a government agency or a court order pursuant to the relevant laws of Hong Kong and legal procedures, the Company shall comply with the law to provide the collected information.
                          </Text>
  
                          <Text paddingBottom="24px" >
                          The Company may share non-personal, internal identifier related information with third parties.
                          </Text>
  
                          <Text paddingBottom="24px" >
                          The vendors or merchants to which the users make payment transfer via the Platform may have their own privacy policies.  The Company is not responsible for such policies and practices.
                          </Text>
  
                      </Box>
  
                      {/* SUBTITLE #3 */}
                      <Box paddingTop="32px" paddingBottom="16px">
                          <Text fontWeight="medium" fontSize="16px">
                              Retention
                              </Text>
                      </Box>
  
                      {/* paragraph section */}
                      <Box color="gray" fontSize="16px">
                          <Text paddingBottom="24px" >
                          The Company will retain your personal data for as long as is required by the PSSVFO and HKMA guidelines, and the Company will respect any additional data protection legislation storage requirement in your respective country.
                              </Text>
                      </Box>
  
                      {/* SUBTITLE #4 */}
                      <Box paddingTop="32px" paddingBottom="16px">
                          <Text fontWeight="medium" fontSize="16px">
                              Usage of collected information
                              </Text>
                      </Box>
  
                      {/* paragraph section */}
                      <Box color="gray" fontSize="16px">
                          <Text paddingBottom="24px" >
                          The collected information concerning personal or non-personal will be used for the purpose of (i) setting up an e-Wallet account for the user to enable user to use the Services, (ii) updating user’s e-Wallet account’s overall activities, (iii) processing payment transfer among users, (iv) collecting fee charges from users, (v) verifying user’s identity and e-Wallet account information, (vi) assisting in uncovering prohibited fraudulent or illegal activities, and (vii) assisting in resolving disputes, and any other legitimate purposes that Yintran needs for operating the Platform and providing the Services, and comply with pursuant to the relevant laws and regulations. The Company may also utilize the collected information from the user to improve the user’s   experience with the Platform and Services, and to contact the user to validate the user’s mobile phone number. The Company may from time to time provide the user with the new Services, new terms and conditions, new Privacy Policy, and new Services based on the information collected from the user.
                              </Text>
                      </Box>
  
                      {/* SUBTITLE #5 */}
                      <Box paddingTop="32px" paddingBottom="16px">
                          <Text fontWeight="medium" fontSize="16px">
                              Enquiry
                              </Text>
                      </Box>
  
                      {/* paragraph section */}
                      <Box color="gray" fontSize="16px">
                          <Text paddingBottom="24px" >
                          If the user has any questions regarding the Policy, the user may contact <Link color="#088AFF" href="mailto:support@yintran.com">support@yintran.com</Link>
                              </Text>
                      </Box>
                  
                  </Box>
  
          </Box>
      </Center>
      )
  }
  