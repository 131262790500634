//export const server_path='http://api-uat.yintran.com/payme/qr3/';

export const server_path='https://app-api.yintran.com/payme/qr3/';
export async function client(endpoint,object) {

  //let url=server_path+endpoint
  let url=server_path+endpoint;
  console.log("Check:"+url);

  try {    
    const response = await fetch(url, {});
    let json = await response.json();
    console.log("Check return json:"+JSON.stringify(json));
    return json;
    //throw new Error(err)
  } catch (err) {
    console.error(err)
  }   
}

client.get = function (endpoint) {

  return client(endpoint,null)
}

client.post = function (endpoint,object) {

  return client(endpoint,object)
}

